import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateContact as onUpdateContact,
  getContactMega as onGetContact,
} from "store/contacts/actions";

const EcommerceAddProduct = () => {
  document.title = "İletişim Bilgileri | Ezgican Admin Paneli";
  const dispatch = useDispatch();
  const [getContactEdit, setContactEdit] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (getContactEdit && getContactEdit.ContactEmail) || '',
      gsmNumber: (getContactEdit && getContactEdit.ContactNumber) || '',
      adress: (getContactEdit && getContactEdit.ContactAddress) || '',
      facebook: (getContactEdit && getContactEdit.FacebookAdd) || '',
      twitter: (getContactEdit && getContactEdit.TwitterAdd) || '',
      instagram: (getContactEdit && getContactEdit.InstagramAdd) || '',

    },
    validationSchema: Yup.object({
      adress: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        ContactEmail: values.email,
        ContactNumber: values.gsmNumber,
        ContactAddress: values.adress,
        FacebookAdd:values.facebook,
        InstagramAdd:values.instagram,
        TwitterAdd:values.twitter




      };
      // update order
      dispatch(onUpdateContact(updateOrder));
      validation.resetForm();

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetContact());

    }
  }, [dispatch, users]);

  useEffect(() => {
    setContactEdit(users[0]);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="İletişim Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>İletişim Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="adress">Adres</Label>
                        <Input
                          id="adress"
                          name="adress"
                          type="text"
                          className="form-control"
                          placeholder="Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.adress || ""}
                          invalid={
                            validation.touched.adress && validation.errors.adress ? true : false
                          }
                        />
                        {validation.touched.adress && validation.errors.adress ? (
                          <FormFeedback type="invalid">{validation.errors.adress}</FormFeedback>
                        ) : null}

                      </div>

                   
                      <div className="mb-3">
                        <Label htmlFor="adress">Facebook Adresi</Label>
                        <Input
                          id="facebook"
                          name="facebook"
                          type="text"
                          className="form-control"
                          placeholder="Facebook Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.facebook || ""}
                          invalid={
                            validation.touched.facebook && validation.errors.facebook ? true : false
                          }
                        />
                        {validation.touched.facebook && validation.errors.facebook ? (
                          <FormFeedback type="invalid">{validation.errors.facebook}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="adress">Instagram Adresi</Label>
                        <Input
                          id="instagram"
                          name="instagram"
                          type="text"
                          className="form-control"
                          placeholder="Instagram Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.instagram || ""}
                          invalid={
                            validation.touched.instagram && validation.errors.instagram ? true : false
                          }
                        />
                        {validation.touched.instagram && validation.errors.instagram ? (
                          <FormFeedback type="invalid">{validation.errors.instagram}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="twitter">Twitter Adresi</Label>
                        <Input
                          id="twitter"
                          name="twitter"
                          type="text"
                          className="form-control"
                          placeholder="Twitter Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.twitter || ""}
                          invalid={
                            validation.touched.twitter && validation.errors.twitter ? true : false
                          }
                        />
                        {validation.touched.twitter && validation.errors.twitter ? (
                          <FormFeedback type="invalid">{validation.errors.twitter}</FormFeedback>
                        ) : null}

                      </div>
                   




                      <div className="mb-3">
                        <Label htmlFor="gsmNumber">Telefon Numarası</Label>
                        <Input
                          id="gsmNumber"
                          name="gsmNumber"
                          type="text"
                          className="form-control"
                          placeholder="Telefon Numarası"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gsmNumber || ""}
                          invalid={
                            validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                          }
                        />
                        {validation.touched.gsmNumber && validation.errors.gsmNumber ? (
                          <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="email">Eposta</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Eposta Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}

                      </div>

                     
               


                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
